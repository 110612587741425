import { SupportButton } from "./styles";
import { useAuth } from "../../contexts/AuthContext";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { isMobile } from "../../utils";
import { useState } from "react";

export const Support = () => {
  const { signed, user } = useAuth();
  const [loadingLogin, setLoadingLogin] = useState(false);

  const loginInSupport = async (cpf: string) => {
    try {
      setLoadingLogin(true);
      const token = localStorage.getItem("@VeloTax:token");
      setLoadingLogin(false);
      const urlSupportSSO = process.env.REACT_APP_SUPPORT_URL ? `${process.env.REACT_APP_SUPPORT_URL}p/sso/${token}` : `http://localhost:3000/p/sso/${token}`;
      window.open(
        urlSupportSSO,
        isMobile() ? "_self" : "_blank"
      );
    } catch (err) {
      setLoadingLogin(false);
    }
  };


  return signed ? (
    <>
      <SupportButton
        onClick={() => {
          loginInSupport(user.user.cpf);
        }}
      >
        <QuestionCircleOutlined />
      </SupportButton>
    </>
  ) : (
    <></>
  );
};
