import ReactInputMask from "react-input-mask";
import { FaUserShield } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AiFillWarning } from "react-icons/ai";
import { Button, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import { Col, Form, message, Row, Modal, Input, Typography, Select } from "antd";
import api from "../../services/api";
import apiBolsa from '../../services/apiBolsa'
import { Container } from "./styles";
import { MeuPlano } from "./MeuPlano";
import { cpfInputMask } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";
import {
  validationCpf,
  validationDateNotRequired,
  validationEmail,
  validationFieldRequired,
  validationFullName,
  validationPassword,
  validationPhone,
} from "../../utils/formValidations";
import { BsFillGearFill, BsInfoCircleFill } from "react-icons/bs";
import { useB3Integration } from "../../contexts/B3IntegrationContext";

const KEY_PASS = "DELETAR";

const MaskCPF = React.forwardRef<HTMLElement>((props, ref) => (
  <ReactInputMask {...props} mask={cpfInputMask} />
));

const MaskBirth = React.forwardRef<HTMLElement>((props, ref) => (
  <ReactInputMask {...props} mask="99/99/9999" />
));

const MaskPhone = React.forwardRef<HTMLElement>((props, ref) => (
  <ReactInputMask {...props} mask="(99) 99999-9999" />
));

export const MinhaConta: React.FC = () => {
  const { user, setUser, signOut } = useAuth();
  const {
    handleIntegrate,
    loadingIntegration,
  } = useB3Integration();
  const [form] = Form.useForm();
  const [warningDeleteModal, showWarningDeleteModal] = useState(false);
  const navigate = useNavigate();
  const [formKey] = Form.useForm();
  const [loading, setLoading] = useState(loadingIntegration);
  const [showPassword, setShowPassword] = useState(false);
  const [keyPass, setKeyPass] = useState("");
  const [fieldsError, setFieldsError] = useState<{ [key: string]: string[] }>(
    {}
  );
  const [modalParametrizacaoOpen, setModalParametrizacaoOpen] = useState(false);
  const [isMesa, setIsMesa] = useState(false);

  useEffect(() => {
    if (user?.user) {
      form.setFieldsValue({
        cpf: user.user.cpf,
        name: user.user.name,
        email: user.user.email,
        birthdate: user.user.birthdate ?? "",
        cellphone: user.user.cellphone ?? "",
      });
    }
  }, [user.user, form]);

  const getMesaOperacoes = useCallback(() => {
    apiBolsa.get('/xpinvestimentos/mesaOperacoes')
      .then((res) => {
        setIsMesa(res?.data?.isMesa || false)
      })
      .catch((err) => {
        message.error('Não foi possível consultar informações de mesa de operações')
      })
  }, [])

  useEffect(() => {
    if(modalParametrizacaoOpen){
      getMesaOperacoes()
    }
  }, [getMesaOperacoes, modalParametrizacaoOpen]);

  const setMesaOperacoes = () => {
    apiBolsa.post('/xpinvestimentos/mesaOperacoes', { isMesa })
      .then((res) => {
        if(res?.status === 202) {
          message.warning(res?.data?.message)
        } else {
          message.success('Suas informações foram salvas')
          getMesaOperacoes()
          handleIntegrate({onlyPrepareBolsa: true})
        }
      })
      .catch(() => {
        message.error('Não foi possível atualizar suas informações de mesa de operações')
      })
  }

  const onFinish = (data: any) => {
    if (loading) {
      return;
    }
    setLoading(true);
    api
      .put("/configuracoes/minhaConta", {
        email: data.email,
        birthdate: data.birthdate,
        cellphone: data.cellphone,
        name: data.name,
        ...(showPassword
          ? {
            pass: data.pass,
            currentPass: data.currentPass,
          }
          : {}),
      })
      .then(() => {
        message.config({
          top: 50,
        });
        message.success("Dados atualizados com sucesso");
        setLoading(false);
        setUser({
          ...user,
          user: {
            ...user.user,
            email: data.email,
            birthdate: data.birthdate,
            cellphone: data.cellphone,
            name: data.name,
          },
        });
      })
      .catch((err) => {
        message.error(
          err.response?.data?.message ||
          "Erro ao atualizar dados. Tente novamente mais tarde!"
        );
        setLoading(false);
      });
  };

  const removeAccount = () => {
    showWarningDeleteModal(true);
  };

  const onFieldsChange = () => {
    setFieldsError(
      form
        .getFieldsError()
        .reduce((acc, cur) => ({ ...acc, [cur.name?.[0]]: cur.errors }), {})
    );
  };

  const onCancel = () => {
    if (loading) {
      return;
    }
    navigate("/carteira");
  };

  const handlePassword = () => {
    setShowPassword((show) => !show);
  };

  const sendDeleteReq = () => {
    if (keyPass.toLowerCase() !== KEY_PASS.toLowerCase()) {
      message.error(`Digite ${KEY_PASS} corretamente!`);
      return;
    }
    api
      .post("/user/remove-user", {
        from: "CALCULADORAV2",
        pass: keyPass,
        cpf: user.user.cpf,
      })
      .then(() => {
        message.success("Conta excluída com sucesso");
        signOut();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  };

  const onDeleteCancel = () => {
    showWarningDeleteModal(false);
    formKey.resetFields();
    setKeyPass("");
  };

  return (
    <Container className="velotax-container">
      <MeuPlano />
      <h1 className="page-title conta">
        Dados do usuário
        <Button
          size="large"
          variant="text"
          color="secondary"
          onClick={() => navigate("/gestaoacesso")}
          startIcon={<FaUserShield color="var(--ant-primary-color)" />}
        >
          Gestão de acessos
        </Button>
      </h1>
      <div className="velotax-content conta">
        <Form
          form={form}
          size="large"
          layout="vertical"
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
        >
          <Form.Item name="name" rules={validationFullName}>
            <TextField
              disabled={loading}
              variant="standard"
              label="Nome completo"
              error={fieldsError["name"]?.length > 0}
            />
          </Form.Item>

          <Form.Item name="cpf" rules={validationCpf}>
            <TextField
              label="CPF"
              disabled={true}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputComponent: MaskCPF as any }}
            />
          </Form.Item>

          <Form.Item name="birthdate" rules={validationDateNotRequired}>
            <TextField
              label="Nascimento"
              variant="standard"
              disabled={loading}
              error={fieldsError["birthdate"]?.length > 0}
              InputProps={{ inputComponent: MaskBirth as any }}
              InputLabelProps={{ shrink: true }}
            />
          </Form.Item>

          <Form.Item name="email" rules={validationEmail}>
            <TextField
              type="email"
              label="Email"
              variant="standard"
              disabled={loading}
            />
          </Form.Item>

          <Form.Item name="cellphone" rules={validationPhone}>
            <TextField
              label="Celular"
              variant="standard"
              disabled={loading}
              error={fieldsError["cellphone"]?.length > 0}
              InputProps={{ inputComponent: MaskPhone as any }}
              InputLabelProps={{ shrink: true }}
            />
          </Form.Item>

          <Button
            color="secondary"
            onClick={handlePassword}
            style={{ padding: 0 }}
          >
            {showPassword ? "Não alterar senha" : "Alterar senha"}
          </Button>

          {showPassword && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item name="currentPass" rules={validationFieldRequired}>
                  <TextField
                    type="password"
                    variant="standard"
                    label="Senha atual"
                    error={fieldsError["currentPass"]?.length > 0}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="pass" rules={validationPassword}>
                  <TextField
                    type="password"
                    variant="standard"
                    label="Nova senha"
                    error={fieldsError["pass"]?.length > 0}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <br />
          <br />

          <Row gutter={[16, 16]} justify="space-between">
            <Col>
              <Button
                size="large"
                color="primary"
                variant="outlined"
                onClick={onCancel}
              >
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                type="submit"
                color="secondary"
                variant="contained"
                endIcon={<CheckOutlined />}
                startIcon={loading && <LoadingOutlined />}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <Row justify="end" className="excluir-conta-container">
        <Col>
          <Button
            size="small"
            disabled={loading}
            onClick={removeAccount}
            style={{
              opacity: 0.8,
              color: "var(--ant-error-color)",
            }}
          >
            Excluir Conta
          </Button>
        </Col>
      </Row>
      <Row className="parametrizar-custos-container">
        {/* <Col>
          <Button onClick={() => setModalParametrizacaoOpen(true)}>
            <BsFillGearFill />
            Parametrizar custos de corretagem
          </Button>
        </Col> */}
      </Row>
      <Modal
        title="Parametrização XP"
        footer={false}
        onCancel={() => setModalParametrizacaoOpen(false)}
        bodyStyle={{ paddingTop: '1rem' }}
        visible={modalParametrizacaoOpen}
        className="parametrizacao-modal"
      >
        <Row style={{ marginBottom: '2rem' }}>
          <Col>
            <Typography.Text style={{ fontWeight: '500' }}>
              <BsInfoCircleFill />
              Funcionalidade exclusiva para investidores que realizam suas operações via Mesa de Operações (assessor).
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography.Text>
              Selecione a parametrização de custos de corretagem:
            </Typography.Text>

          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Select
              size='large'
              value={isMesa ? 'Mesa de operações' : 'Não considerar'}
              onChange={(e: any) => {setIsMesa(e === 'Mesa de operações' ? true : false)}}
              options={[
                { label: 'Não considerar', value: 'Não considerar' },
                { label: 'Mesa de operações', value: 'Mesa de operações' }
              ]}
              defaultValue={isMesa ? 'Mesa de operações' : 'Não considerar'} />
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="end">
          <Col>
            <Button
              size="large"
              type="submit"
              color="secondary"
              variant="contained"
              endIcon={<CheckOutlined />}
              startIcon={loading && <LoadingOutlined />}
              onClick={() => {
                setMesaOperacoes()
                setModalParametrizacaoOpen(false)
              }}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        okText="Excluir"
        cancelText="Cancelar"
        title="Exclusão de conta"
        onOk={sendDeleteReq}
        onCancel={onDeleteCancel}
        visible={warningDeleteModal}
      >
        <Row gutter={[16, 16]} align="middle">
          <Col span={10}>
            <AiFillWarning
              size={100}
              color="var(--ant-warning-color)"
              style={{ display: "block", margin: "0 auto" }}
            />
          </Col>
          <Col span={14}>
            <Typography.Paragraph strong style={{ fontSize: "1rem" }}>
              Deseja realmente excluir sua conta?
            </Typography.Paragraph>
            <Typography.Paragraph>
              Lembre-se de que todos os seus dados também serão excluídos. Caso
              possua um plano ativo, você também perderá todas as
              funcionalidades.
            </Typography.Paragraph>
          </Col>
          <Col span={24}>
            <Typography style={{ fontSize: "1rem" }}>
              Digite <strong>{KEY_PASS}</strong> para prosseguir:
            </Typography>
          </Col>
          <Col span={24}>
            <Form
              form={formKey}
              onChange={(event: any) => {
                setKeyPass(event.target.value);
              }}
            >
              <Form.Item name="key">
                <Input placeholder={KEY_PASS} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </Container>
  );
};
