import styled from "styled-components";

export const SupportButton = styled.div`
  position: fixed;
  width: 3rem;
  height: 3rem;
  right: 0.625rem;
  top: 0.625rem;
  z-index: 100;
  display: flex;
  cursor: pointer;
  user-select: none;
  border-radius: 2rem;
  align-items: center;
  justify-content: center;
  transition: 0.3s filter;
  :hover {
    filter: contrast(1.2);
  }
  :active {
    filter: contrast(1.5);
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    stroke: white;
    margin-top: -0.25rem;
  }

  &.hide {
    display: none;
  }

  @media (max-device-width: 812px) {
    display: none;
  }
`;
