import styled from 'styled-components';

export const Container = styled.div`
    padding: 64px 32px 128px;

    h2.ant-typography {
        display: flex;
        align-items: center;
        column-gap: 8px;
        width: 700px;
        margin: 0 auto 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: var(--velotax-font-color-light);

        &.info {
            align-items: flex-start;
            font-size: 0.875rem;
            line-height: 1.125rem;
            opacity: 0.85;
            margin: 0 auto 2.5rem;
            svg {
                margin-top: 4px;
            }
        }
    }

    @media only screen and (max-device-width: 812px) {
        padding: 0;

        h2.ant-typography {
            width: 100%;
            margin: 0 auto 1.5rem;
            padding: 0 24px;
        }
    }
`;

export const Content = styled.div`
  padding: 32px;
  margin: 0 auto;
  width: 700px;
  border-radius: 4px;
  background-color: var(--velotax-background-color);

    /* .ant-list.ant-list-split {
    margin: 24px 0 0;
    padding: 0 16px;
    background-color: var(--velotax-ghost);
    border: 1px solid var(--velotax-ghost);
    border-radius: 4px;
    .ant-list-item-action > li {
      padding: 0;
    }
    .ant-list-item-meta-description {
      opacity: 0.75;
    }
    .ant-list-item-action > li,
    .ant-list-item-meta-title,
    .ant-list-item-meta-description {
      color: var(--velotax-font-color);
    }
    .ant-list-item-action-split {
      opacity: 0;
    }
  } */
    @media only screen and (max-device-width: 812px) {
        width: 100%;
        padding: 24px;
        border-radius: 0;
        /* .ant-list.ant-list-split {
      .ant-list-item-action {
        display: grid;
        align-items: end;
        grid-template-rows: 1fr 0.75fr;
        grid-template-columns: 1fr 1fr;
      }
    } */
    }
`;

export const BottomAdvice = styled.div`
    margin-top: 40px;
    width: 700px;

    line-height: 24px;

    @media only screen and (max-device-width: 812px) {
        width: 100%;
        padding: 24px;
    }
`;

export const AttentionIconContainer = styled.div`
    position: relative;
    display: inline;
    margin-right: 3px;
    width: 14px;

    & svg {
        position: relative;
        top: 2px;
    }
`;
