import { ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import { Provider } from "@rollbar/react";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import Routes from "./routes";
import GlobalStyles from "./styles/global";
import { ChatGPT } from "./components/ChatGPT";
import ContainerStyles from "./styles/container";
import DrawerModalStyles from "./styles/drawer-modal";
import ContextWrapper from "./contexts/ContextWrapper";
import HistoricListStyles from "./styles/historic-list";
import ErrorBoundaryRollbar from "./components/ErrorBoundary";
import { Support } from "./components/Support";

function App() {
  const { host } = window.location;
  const isDev = host.includes("localhost") || host.includes("dev");
  const rollbarConfig = {
    accessToken: "30ca0272bd6e40e0bd69c225fa9e2c64",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: isDev ? "development" : "production",
    },
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#18191a",
      },
      secondary: {
        main: "#fbc105",
      },
      info: {
        main: "#ffff",
      },
    },
  });

  return (
    <Provider config={rollbarConfig}>
      <ThemeProvider theme={theme}>
        <ErrorBoundaryRollbar>
          <ConfigProvider
            locale={ptBR}
            renderEmpty={() => "Nenhum dado encontrado"}
          >
            <Router>
              <ContextWrapper>
                <Routes />
                <GlobalStyles />
                {/* <ZendeskStyles /> */}
                {/* <ZendeskWidget /> */}
                <ContainerStyles />
                <DrawerModalStyles />
                <HistoricListStyles />
                <ChatGPT />
                <Support />
              </ContextWrapper>
            </Router>
          </ConfigProvider>
        </ErrorBoundaryRollbar>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
